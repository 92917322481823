/* eslint-disable quotes */
export const appConfig =
{
  "apiKey": "AIzaSyBdxFBtt-CHAj5OJujW5zNBSEzQ2uLYkUw",
  "appId": "1:221727723649:web:442b501753a11f1152bd80",
  "authDomain": "schooldog-i-wheeler-ga.firebaseapp.com",
  "measurementId": "G-F86H73LZVM",
  "messagingSenderId": "221727723649",
  "project": "schooldog-i-wheeler-ga",
  "projectId": "schooldog-i-wheeler-ga",
  "showGaBranding": true,
  "storageBucket": "schooldog-i-wheeler-ga.appspot.com"
}
